<template>
   <div class="">
    <!-- 顶部 -->
    <topheader :type="activeIndex"></topheader>
    <router-view />
    <!-- 页脚 -->
    <bottomfooter></bottomfooter>
  </div>
</template>

<script>
import topheader from '@/components/TopHeader.vue'
import bottomfooter from '@/components/Footer.vue'
// import apiUrl from '@/api/main'
export default {
  name: '',
  components: {  topheader, bottomfooter  },
  data() {
    return {
      activeIndex: 2
    };
  },
  mounted(){
  },
 
  methods:{

 
  }
};
</script>

<style lang='scss' scoped>

</style>